import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment/moment';
import Modal from 'react-bootstrap/Modal';

import { FormData } from '../helpers/FormData';
import { ModalDialog } from "../components/ModalDialog.component";
import { _customPriceDelete, _customPriceGetById, _customPriceHistoryGet, _customPricesGet, _usersListGet } from "../services/apiConnections.service";

export const PersonalizedPricing = () => {
    const [prices, setPrices] = useState([]);
    const [emails, setEmails] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [priceToDelete, setPriceToDelete] = useState({});
    const [showHistory, setShowHistory] = useState(false);
    const [priceHistory, setPriceHistory] = useState([]);
    const [showCustomPrice, setShowCustomPrice] = useState(false);
    const [priceToShowId, setPriceToShowId] = useState(null);
    const [priceToShowTitle, setPriceToShowTitle] = useState(null);
    const [priceToShow, setPriceToShow] = useState([]);
    const [error, setError] = useState(null);
    const [maxStartTime, setMaxStartTime] = useState(new Date());
    const [maxEndTime, setMaxEndTime] = useState(new Date());

    const [filterValues, handleFilterValueChange, setFilterValues] = FormData({
        date_from: new Date(
            new Date(
                new Date().setMonth(
                    new Date().getMonth() - 1
                )
            )
        ),
        date_to: new Date(),
        user: {
            label: "All",
            value: -1
        }
    });

    let startOfTheDay = new Date(new Date().setHours(0)).setMinutes(0);

    useEffect(() => {
        if (!prices.length && !emptyTable) {
            handleFilter();
        }
    }, [prices, emptyTable]);

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        handleFilter();
    }, [filterValues]);

    useEffect(() => {
        if (filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
            filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
            filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()) {
            setMaxStartTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxStartTime(new Date());
        }
        if (filterValues.date_from > filterValues.date_to) {
            handleFilterValueChange('date_to', filterValues.date_from);
        }
    }, [filterValues.date_from, filterValues.date_to]);

    useEffect(() => {
        let now = new Date();
        if (filterValues.date_to.getDate() < now.getDate() ||
            filterValues.date_to.getMonth() < now.getMonth() ||
            filterValues.date_to.getFullYear() < now.getFullYear()) {
            setMaxEndTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxEndTime(new Date());
        }
    }, [filterValues.date_to]);

    const handleEmails = async () => {
        let result = await _usersListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            arr.unshift({
                label: "All",
                value: -1
            })
            setEmails(arr);
        }
    };

    const handleFilter = () => {
        let searchObj = {
            date_from: dateToLocalISO(filterValues.date_from) || null,
            date_to: dateToLocalISO(filterValues.date_to) || null,
            user_id: filterValues.user ? filterValues.user.value : null,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (filterValues.user && filterValues.user.value === -1) searchObj.user_id = null;
        let search = `limit=500`;
        for (let e in searchObj) {
            if (searchObj[e] != null) {
                search += `&${e}=${searchObj[e]}`;
            }
        };
        getData(search)
    };

    const getData = async (search = '') => {
        let result = await _customPricesGet(search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.price_lists.length);
            for (let s in result.price_lists) {
                result.price_lists[s].admin = JSON.parse(result.price_lists[s].admin).user_email;
            }
            setPrices(result.price_lists);
        }
        setError(result.error);
    };

    const handleShowHistory = async (id) => {
        let result = await _customPriceHistoryGet(id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            for (let s in result.price_list_history) {
                result.price_list_history[s].admin = JSON.parse(result.price_list_history[s].admin).user_email;
            }
            setPriceHistory(result.price_list_history);
        }
        setError(result.error);
    };

    const handleGetCustomPrice = async (id, search = '') => {
        let result = await _customPriceGetById(id, search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setPriceToShow(result.prices);
            setPriceToShowTitle(result.user_email);
        };
        setError(result.error);
    };

    const handlePricesFilter = (search) => {
        handleGetCustomPrice(priceToShowId, search);
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    const dateTimeColumnFormatter = (cell, row) => {
        return (
            <span>{moment(new Date(cell)).format('yyyy-MM-DD H:mm')}</span>
        );
    };

    const dateColumnFormatter = (cell, row) => {
        return (
            <span>{moment(new Date(cell)).format('yyyy-MM-DD')}</span>
        );
    };

    const viewFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn'
                onClick={() => {
                    setPriceToShowId(cell);
                    handleGetCustomPrice(cell)
                    setShowCustomPrice(true);
                    console.log(priceToShow);
                }}>
                <FontAwesomeIcon icon={solid('eye')} />
            </button>
        )
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/custom-prices/${cell}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setPriceToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeletePrice = async (id) => {
        await _customPriceDelete(id);
        handleFilter();
        setShowDeleteDialog(false);
    };

    const historyFormatter = (cell, row) => {
        return (
            <button
                className="historyBtn"
                onClick={() => {
                    setShowHistory(true);
                    handleShowHistory(cell);
                }}>Show Logs</button>
        )
    };

    const columns = [
        {
            dataField: 'user_email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'created_at',
            text: 'Date Added',
            sort: true,
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'start_at',
            text: 'Effective From',
            sort: true,
            formatter: dateColumnFormatter
        },
        {
            dataField: 'admin',
            text: 'Admin',
            sort: true
        },
        {
            dataField: 'details',
            text: 'Description',
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true
        },
        {
            dataField: 'id',
            text: 'View',
            classes: 'iconColumn',
            formatter: viewFormatter
        },
        {
            dataField: 'id',
            text: 'Edit',
            classes: 'iconColumn',
            formatter: editFormatter
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        },
        {
            dataField: 'id',
            text: 'History',
            formatter: historyFormatter
        }
    ];

    const paymentHistoryColumns = [
        {
            dataField: 'user_email',
            text: 'Email',
        },
        {
            dataField: 'created_at',
            text: 'Date Added',
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'start_at',
            text: 'Effective Date',
            formatter: dateColumnFormatter
        },
        {
            dataField: 'admin',
            text: 'Admin',
        },
        {
            dataField: 'updated_at',
            text: 'Edit Date',
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'details',
            text: 'Description',
        }
    ];

    const customPriceColumns = [
        {
            dataField: 'name',
            text: 'Destination',
            sort: true
        },
        {
            dataField: 'direction',
            text: 'Code',
            sort: true,
            classes: 'columnW-11rem'
        },
        {
            dataField: 'cost',
            text: 'Price/SMS',
            sort: true,
            sortFunc: (a, b, order) => {
                if (order === "asc") {
                    return Number(a) - Number(b);
                } else if (order === "desc") {
                    return Number(b) - Number(a);
                }
            },
            classes: 'columnW-11rem'
        }
    ];

    const defaultSorted = [
        {
            dataField: 'updated_at',
            order: 'desc'
        }
    ];

    const defaultSortedHistory = [
        {
            dataField: 'updated_at',
            order: 'asc'
        }
    ];

    const defaultSortedPrices = [
        {
            dataField: 'name',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Personalized Pricing</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <Link className='mainBtn btn' to={'/upload-custom-price'}>Upload Price</Link>
                                <div className='dateTimePickerBlock'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>From</label>
                                        <DatePicker
                                            selected={filterValues.date_from}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_from',
                                                    new Date(date));
                                            }}
                                            selectsStart
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            maxDate={filterValues.date_to}
                                            maxTime={maxStartTime}
                                            minTime={startOfTheDay}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>To</label>
                                        <DatePicker
                                            selected={filterValues.date_to}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_to',
                                                    new Date(date));
                                            }}
                                            selectsEnd
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            minDate={filterValues.date_from}
                                            maxDate={new Date()}
                                            minTime={
                                                (
                                                    filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
                                                    filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
                                                    filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()
                                                ) ?
                                                    startOfTheDay :
                                                    filterValues.date_from
                                            }
                                            maxTime={maxEndTime}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label
                                        className='inputLabel'
                                        htmlFor='email'>Email</label>
                                    <Select
                                        id='email'
                                        name='email'
                                        className='customSelect'
                                        classNamePrefix='select'
                                        options={emails}
                                        placeholder='Select user'
                                        value={filterValues.user}
                                        onChange={(selectedOption) => handleFilterValueChange(
                                            'user',
                                            selectedOption
                                        )} />
                                </div>
                            </div>
                            <BootstrapTable
                                keyField="id"
                                data={prices}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeletePrice(priceToDelete.id)}
                    title={`Delete price`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete this price? This action will impact the user's prices`}</span>
                </ModalDialog>
                <Modal
                    show={showHistory}
                    onHide={() => setShowHistory(false)}
                    backdrop="static"
                    keyboard={false}
                    className="historyModal"
                    centered>
                    <Modal.Header closeButton>
                        <div className='pageTitle'>
                            <span>History</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <BootstrapTable
                            keyField='updated_at'
                            data={priceHistory}
                            columns={paymentHistoryColumns}
                            wrapperClasses='table-responsive'
                            bordered={false}
                            defaultSorted={defaultSortedHistory}
                            noDataIndication={noData} />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showCustomPrice}
                    onHide={() => setShowCustomPrice(false)}
                    backdrop="static"
                    keyboard={false}
                    className="historyModal fixedDialogTable"
                    centered>
                    <Modal.Header closeButton>
                        <div className='pageTitle'>
                            <span>{priceToShowTitle}</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-lg-3">
                            <input
                                className='searchInput'
                                placeholder='Search...'
                                onChange={(event) => handlePricesFilter(event.target.value)} />
                        </div>
                        <BootstrapTable
                            keyField='direction'
                            data={priceToShow}
                            columns={customPriceColumns}
                            wrapperClasses='table-responsive'
                            bordered={false}
                            defaultSorted={defaultSortedPrices}
                            pagination={paginationFactory(options)}
                            noDataIndication={noData} />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
};