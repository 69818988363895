import React from "react";

export const SubMenu = (props) => {

    return (
        <div className="subMenuWrapper">
            <div className="subMenuTitle">
                <span>{props.title}</span>
            </div>
            <ul className="subMenuChildren">
                {props.children}
            </ul>
        </div>
    )
};