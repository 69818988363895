import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment/moment';
import Modal from 'react-bootstrap/Modal';

import { FormData } from '../helpers/FormData';
import { ModalDialog } from "../components/ModalDialog.component";
import { _paymentDelete, _paymentHistoryGet, _paymentsGet, _usersListGet } from "../services/apiConnections.service";

export const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [emails, setEmails] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [paymentToDelete, setPaymentToDelete] = useState({});
    const [showHistory, setShowHistory] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState([])
    const [error, setError] = useState(null);
    const [maxStartTime, setMaxStartTime] = useState(new Date());
    const [maxEndTime, setMaxEndTime] = useState(new Date());

    const [filterValues, handleFilterValueChange, setFilterValues] = FormData({
        date_from: new Date(
            new Date(
                new Date().setMonth(
                    new Date().getMonth() - 1
                )
            )
        ),
        date_to: new Date(),
        user: {
            label: "All",
            value: -1
        }
    });

    let startOfTheDay = new Date(new Date().setHours(0)).setMinutes(0);

    useEffect(() => {
        if (!payments.length && !emptyTable) {
            handleFilter();
        }
    }, [payments, emptyTable]);

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        handleFilter();
    }, [filterValues]);

    useEffect(() => {
        if (filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
            filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
            filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()) {
            setMaxStartTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxStartTime(new Date());
        }
        if (filterValues.date_from > filterValues.date_to) {
            handleFilterValueChange('date_to', filterValues.date_from);
        }
    }, [filterValues.date_from, filterValues.date_to]);

    useEffect(() => {
        let now = new Date();
        if (filterValues.date_to.getDate() < now.getDate() ||
            filterValues.date_to.getMonth() < now.getMonth() ||
            filterValues.date_to.getFullYear() < now.getFullYear()) {
            setMaxEndTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxEndTime(new Date());
        }
    }, [filterValues.date_to]);

    const handleEmails = async () => {
        let result = await _usersListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            arr.unshift({
                label: "All",
                value: -1
            })
            setEmails(arr);
        }
    };

    const handleFilter = () => {
        let searchObj = {
            date_from: dateToLocalISO(filterValues.date_from) || null,
            date_to: dateToLocalISO(filterValues.date_to) || null,
            user_id: filterValues.user ? filterValues.user.value : null
        };
        if (filterValues.user && filterValues.user.value === -1) searchObj.user_id = null;
        let search = `limit=500`;
        for (let e in searchObj) {
            if (searchObj[e] != null) {
                search += `&${e}=${searchObj[e]}`;
            }
        };
        getData(search)
    };

    const handleShowHistory = async (id) => {
        let result = await _paymentHistoryGet(id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            for (let s in result.payment_history) {
                result.payment_history[s].admin = JSON.parse(result.payment_history[s].admin).user_email;
            }
            setPaymentHistory(result.payment_history);
        }
        setError(result.error);
    };

    const getData = async (search = '') => {
        let result = await _paymentsGet(search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.payments.length);
            for (let s in result.payments) {
                result.payments[s].admin = JSON.parse(result.payments[s].admin).user_email;
            }
            setPayments(result.payments);
        }
        setError(result.error);
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    const dateColumnFormatter = (cell, row) => {
        return (
            <span>{moment(new Date(cell)).format('yyyy-MM-DD H:mm')}</span>
        );
    };

    const moneyColumnFormatter = (cell, row) => {
        return (
            <span>{Number(cell).toFixed(2)}</span>
        )
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/payment/${cell}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setPaymentToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeletePayment = async (id) => {
        await _paymentDelete(id);
        handleFilter();
        setShowDeleteDialog(false);
    };

    const historyFormatter = (cell, row) => {
        return (
            <button
                className="historyBtn"
                onClick={() => {
                    setShowHistory(true);
                    handleShowHistory(cell);
                }}>Show Logs</button>
        )
    };

    const columns = [
        {
            dataField: 'user_email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'payment_date',
            text: 'Payment Date',
            sort: true,
            formatter: dateColumnFormatter
        },
        {
            dataField: 'amount',
            text: 'Paid Amount',
            sort: true,
            formatter: moneyColumnFormatter
        },
        {
            dataField: 'balance_after',
            text: 'Balance',
            sort: true,
            formatter: moneyColumnFormatter
        },
        {
            dataField: 'admin',
            text: 'Admin',
            sort: true
        },
        {
            dataField: 'details',
            text: 'Description',
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true
        },
        {
            dataField: 'id',
            text: 'Edit',
            classes: 'iconColumn',
            formatter: editFormatter
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        },
        {
            dataField: 'id',
            text: 'History',
            formatter: historyFormatter
        }
    ];

    const paymentHistoryColumns = [
        {
            dataField: 'user_email',
            text: 'Email',
        },
        {
            dataField: 'payment_date',
            text: 'Payment Date',
            formatter: dateColumnFormatter
        },
        {
            dataField: 'amount',
            text: 'Paid Amount',
        },
        {
            dataField: 'admin',
            text: 'Admin',
        },
        {
            dataField: 'edit_date',
            text: 'Edit Date',
            formatter: dateColumnFormatter
        },
        {
            dataField: 'details',
            text: 'Description',
        }
    ];

    const defaultSorted = [
        {
            dataField: 'payment_date',
            order: 'desc'
        }
    ];

    const defaultSortedHistory = [
        {
            dataField: 'edit_date',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };
    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Payment</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <Link className='mainBtn btn' to={'/add-payment'}>Add Payment</Link>
                                <div className='dateTimePickerBlock'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>From</label>
                                        <DatePicker
                                            selected={filterValues.date_from}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_from',
                                                    new Date(date));
                                            }}
                                            selectsStart
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            maxDate={filterValues.date_to}
                                            maxTime={maxStartTime}
                                            minTime={startOfTheDay}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>To</label>
                                        <DatePicker
                                            selected={filterValues.date_to}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_to',
                                                    new Date(date));
                                            }}
                                            selectsEnd
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            minDate={filterValues.date_from}
                                            maxDate={new Date()}
                                            minTime={
                                                (
                                                    filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
                                                    filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
                                                    filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()
                                                ) ?
                                                startOfTheDay :
                                                filterValues.date_from
                                            }
                                            maxTime={maxEndTime}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label
                                        className='inputLabel'
                                        htmlFor='email'>Email</label>
                                    <Select
                                        id='email'
                                        name='email'
                                        className='customSelect'
                                        classNamePrefix='select'
                                        options={emails}
                                        placeholder='Select user'
                                        value={filterValues.user}
                                        onChange={(selectedOption) => handleFilterValueChange(
                                            'user',
                                            selectedOption
                                        )} />
                                </div>
                            </div>
                            <BootstrapTable
                                keyField='ID'
                                data={payments}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeletePayment(paymentToDelete.id)}
                    title={`Delete payment`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete this payment? This action will impact the user's balance`}</span>
                </ModalDialog>
                <Modal
                    show={showHistory}
                    onHide={() => setShowHistory(false)}
                    backdrop="static"
                    keyboard={false}
                    className="historyModal"
                    centered>
                    <Modal.Header closeButton>
                        <div className='pageTitle'>
                            <span>History</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <BootstrapTable
                            keyField='edit_date'
                            data={paymentHistory}
                            columns={paymentHistoryColumns}
                            wrapperClasses='table-responsive'
                            bordered={false}
                            defaultSorted={defaultSortedHistory}
                            noDataIndication={noData} />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
};