import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';

import { FormData } from '../helpers/FormData';
import { _customSenderAdd, _customSenderEdit, _customSenderGetById, _usersListGet } from '../services/apiConnections.service';

export const CustomSenderUpsert = (props) => {


    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        sender: '',
        description: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [emails, setEmails] = useState([]);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        if (props.edit) {
            handleSenderGet();
        }
    }, [props]);

    const handleEmails = async () => {
        let result = await _usersListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            setEmails(arr);
        }
    };

    const handleSenderGet = async () => {
        let result = await _customSenderGetById(params.id);
        if (result.error) {
            console.log("--error: ", result.error);
        } else {
            setFormValues({
                email: {
                    label: result.user_email,
                    value: result.user_id
                },
                sender: result.number,
                description: result.details,
            });
        }
    };

    const handleUpsert = async (event) => {
        event.preventDefault();
        let data = {
            details: formValues.description,
            user_id: formValues.email.value,
            number: formValues.sender
        }
        let result;

        if (!props.edit)
            result = await _customSenderAdd(data);
        else
            result = await _customSenderEdit(data, params.id);

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/custom-senders');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'sender':
                if (target.value.length > 255) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        sender: "Maximum number of characters - 255"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['sender'];
                    setFormErrors(copy);
                }
                break;
            case 'description':
                if (target.value.length > 255) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Maximum number of characters - 255"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['description'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.email && formValues.sender.length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Create Sender' : 'Edit Sender'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='email'>Email</label>
                        <div className='inputWrapper'>
                            <Select
                                id='email'
                                name='email'
                                className='customSelect'
                                classNamePrefix='select'
                                options={emails}
                                placeholder='Select Email'
                                value={formValues.email}
                                isDisabled={props.edit}
                                onChange={(selectedOption) => handleFormValueChange(
                                    'email',
                                    selectedOption
                                )} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='sender'>Sender Name</label>
                        <div className='inputWrapper'>
                            <input
                                id='sender'
                                name='sender'
                                type="text"
                                className={`formInput ${formErrors.sender ? 'invalidInput' : ''}`}
                                placeholder="Sender Name"
                                value={formValues.sender}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'sender',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.sender}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel'
                            htmlFor='description'>Description</label>
                        <div className='inputWrapper'>
                            <textarea
                                name='description'
                                id='description'
                                className={`formInput formTextArea ${formErrors.description ? 'invalidInput' : ''}`}
                                placeholder='Description'
                                value={formValues.description}
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}></textarea>
                            <span className='fieldError'>{formErrors.description}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            style={{ 'marginRight': '10px' }}
                            onClick={() => { navigate('/custom-senders') }}
                            className={`mainBtn btn`}>Cancel</button>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};