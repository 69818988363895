import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import moment from 'moment/moment';
import Modal from 'react-bootstrap/Modal';

import { FormData } from '../helpers/FormData';
import { ModalDialog } from "../components/ModalDialog.component";
import { _customSenderDelete, _customSenderHistoryGet, _customSendersGet, _customSendersShortListGet, _usersListGet } from "../services/apiConnections.service";

export const PersonalizedSender = () => {
    const [customSenders, setCustomSenders] = useState([]);
    const [emails, setEmails] = useState([]);
    const [senders, setSenders] = useState([]);
    const [emptyTable, setEmptyTable] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [senderToDelete, setSenderToDelete] = useState({});
    const [showHistory, setShowHistory] = useState(false);
    const [senderHistory, setSenderHistory] = useState([]);
    const [error, setError] = useState(null);
    const [maxStartTime, setMaxStartTime] = useState(new Date());
    const [maxEndTime, setMaxEndTime] = useState(new Date());

    const [filterValues, handleFilterValueChange, setFilterValues] = FormData({
        date_from: new Date(
            new Date(
                new Date().setMonth(
                    new Date().getMonth() - 1
                )
            )
        ),
        date_to: new Date(),
        user: {
            label: "All",
            value: -1
        },
        sender: {
            label: "All",
            value: -1
        }
    });

    let startOfTheDay = new Date(new Date().setHours(0)).setMinutes(0);

    useEffect(() => {
        if (!customSenders.length && !emptyTable) {
            handleFilter();
        }
    }, [customSenders, emptyTable]);

    useEffect(() => {
        if (!emails.length && !senders.length) {
            handleEmails();
            handleSenders();
        }
    }, [emails, senders]);

    useEffect(() => {
        handleFilter();
    }, [filterValues]);

    useEffect(() => {
        if (filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
            filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
            filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()) {
            setMaxStartTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxStartTime(new Date());
        }
        if (filterValues.date_from > filterValues.date_to) {
            handleFilterValueChange('date_to', filterValues.date_from);
        }
    }, [filterValues.date_from, filterValues.date_to]);

    useEffect(() => {
        let now = new Date();
        if (filterValues.date_to.getDate() < now.getDate() ||
            filterValues.date_to.getMonth() < now.getMonth() ||
            filterValues.date_to.getFullYear() < now.getFullYear()) {
            setMaxEndTime(new Date(new Date().setHours(23)).setMinutes(59));
        } else {
            setMaxEndTime(new Date());
        }
    }, [filterValues.date_to]);

    const handleEmails = async () => {
        let result = await _usersListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            arr.unshift({
                label: "All",
                value: -1
            })
            setEmails(arr);
        }
    };

    const handleSenders = async () => {
        let result = await _customSendersShortListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.senders.map((e) => {
                return {
                    label: e.number,
                    value: e.id
                }
            });
            arr.unshift({
                label: "All",
                value: -1
            })
            setSenders(arr);
        }
    };

    const handleFilter = () => {
        let searchObj = {
            date_from: dateToLocalISO(filterValues.date_from) || null,
            date_to: dateToLocalISO(filterValues.date_to) || null,
            user_id: filterValues.user ? filterValues.user.value : null,
            sender_id: filterValues.sender ? filterValues.sender.value : null,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        if (filterValues.user && filterValues.user.value === -1) searchObj.user_id = null;
        if (filterValues.sender && filterValues.sender.value === -1) searchObj.sender_id = null;
        let search = `limit=500`;
        for (let e in searchObj) {
            if (searchObj[e] != null) {
                search += `&${e}=${searchObj[e]}`;
            }
        };
        getData(search)
    };

    const getData = async (search = '') => {
        let result = await _customSendersGet(search);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            setEmptyTable(!result.senders.length);
            for (let s in result.senders) {
                result.senders[s].admin = JSON.parse(result.senders[s].admin).user_email;
            }
            setCustomSenders(result.senders);
        }
        setError(result.error);
    };

    const handleShowHistory = async (id) => {
        let result = await _customSenderHistoryGet(id);
        if (result.error) {
            console.log("--error:", result.error);
        } else {
            for (let s in result.sender_history) {
                result.sender_history[s].admin = JSON.parse(result.sender_history[s].admin).user_email;
            }
            setSenderHistory(result.sender_history);
        }
        setError(result.error);
    };

    const dateToLocalISO = (date) => {
        let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
        let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
        return localISOTime;
    };

    const dateTimeColumnFormatter = (cell, row) => {
        return (
            <span>{moment(new Date(cell)).format('yyyy-MM-DD H:mm')}</span>
        );
    };

    const editFormatter = (cell, row) => {
        return (
            <Link className='tableIconBtn' to={`/custom-senders/${cell}`}>
                <FontAwesomeIcon icon={solid('pencil')} />
            </Link>
        )
    };

    const deleteFormatter = (cell, row) => {
        return (
            <button
                className='tableIconBtn trashBtn'
                onClick={() => {
                    setSenderToDelete(row);
                    setShowDeleteDialog(true);
                }}>
                <FontAwesomeIcon icon={regular('trash-can')} />
            </button>
        )
    };

    const handleDeleteSender = async (id) => {
        await _customSenderDelete(id);
        handleFilter();
        setShowDeleteDialog(false);
    };

    const historyFormatter = (cell, row) => {
        return (
            <button
                className="historyBtn"
                onClick={() => {
                    setShowHistory(true);
                    handleShowHistory(cell);
                }}>Show Logs</button>
        )
    };

    const columns = [
        {
            dataField: 'user_email',
            text: 'Email',
            sort: true
        },
        {
            dataField: 'number',
            text: 'Sender',
            sort: true
        },
        {
            dataField: 'created_at',
            text: 'Date Added',
            sort: true,
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'admin',
            text: 'Admin',
            sort: true
        },
        {
            dataField: 'details',
            text: 'Description',
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true
        },
        {
            dataField: 'id',
            text: 'Edit',
            classes: 'iconColumn',
            formatter: editFormatter
        },
        {
            dataField: 'user_id',
            text: 'Delete',
            classes: 'iconColumn',
            formatter: deleteFormatter
        },
        {
            dataField: 'id',
            text: 'History',
            formatter: historyFormatter
        }
    ];

    const senderHistoryColumns = [
        {
            dataField: 'user_email',
            text: 'Email',
        },
        {
            dataField: 'number',
            text: 'Sender'
        },
        {
            dataField: 'created_at',
            text: 'Date Added',
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'admin',
            text: 'Admin',
        },
        {
            dataField: 'edit_date',
            text: 'Edit Date',
            formatter: dateTimeColumnFormatter
        },
        {
            dataField: 'details',
            text: 'Description',
        }
    ];

    const defaultSorted = [
        {
            dataField: 'created_at',
            order: 'desc'
        }
    ];

    const defaultSortedHistory = [
        {
            dataField: 'edit_date',
            order: 'asc'
        }
    ];

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total"> Showing {from} to {to} of {size} Results</span>
    );

    const options = {
        hidePageListOnlyOnePage: true,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: '10', value: 10
            },
            {
                text: '25', value: 25
            },
            {
                text: '50', value: 50
            },
            {
                text: '100', value: 100
            }
        ]
    };

    const noData = () => {
        return <p className='emptyTableMessage'>No matching records found</p>
    };

    return (
        <div className='content wideContent'>
            <div>
                <div className='pageTitle'>
                    <span>Personalized Sender</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='myTable'>
                            <div className='tableHeader'>
                                <Link className='mainBtn btn' to={'/custom-sender-create'}>Create Sender</Link>
                                <div className='dateTimePickerBlock'>
                                    <div className='col-6'>
                                        <label className='inputLabel'>From</label>
                                        <DatePicker
                                            selected={filterValues.date_from}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_from',
                                                    new Date(date));
                                            }}
                                            selectsStart
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            maxDate={filterValues.date_to}
                                            maxTime={maxStartTime}
                                            minTime={startOfTheDay}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label className='inputLabel'>To</label>
                                        <DatePicker
                                            selected={filterValues.date_to}
                                            onChange={(date) => {
                                                handleFilterValueChange(
                                                    'date_to',
                                                    new Date(date));
                                            }}
                                            selectsEnd
                                            showTimeSelect
                                            dateFormat="yyyy-MM-dd H:mm"
                                            timeFormat='H:mm'
                                            minDate={filterValues.date_from}
                                            maxDate={new Date()}
                                            minTime={
                                                (
                                                    filterValues.date_from.getDate() < filterValues.date_to.getDate() ||
                                                    filterValues.date_from.getMonth() < filterValues.date_to.getMonth() ||
                                                    filterValues.date_from.getFullYear() < filterValues.date_to.getFullYear()
                                                ) ?
                                                    startOfTheDay :
                                                    filterValues.date_from
                                            }
                                            maxTime={maxEndTime}
                                            className='formInput datePicker'
                                        />
                                    </div>
                                </div>
                                <div className="mb-3 fewFilters">
                                    <div className="col-6">
                                        <label
                                            className='inputLabel'
                                            htmlFor='email'>Email</label>
                                        <Select
                                            id='email'
                                            name='email'
                                            className='customSelect'
                                            classNamePrefix='select'
                                            options={emails}
                                            placeholder='Select user'
                                            value={filterValues.user}
                                            onChange={(selectedOption) => handleFilterValueChange(
                                                'user',
                                                selectedOption
                                            )} />
                                    </div>
                                    <div className="col-6">
                                        <label
                                            className='inputLabel'
                                            htmlFor='sender'>Sender</label>
                                        <Select
                                            id='sender'
                                            name='sender'
                                            className='customSelect'
                                            classNamePrefix='select'
                                            options={senders}
                                            placeholder='Select sender'
                                            value={filterValues.sender}
                                            onChange={(selectedOption) => handleFilterValueChange(
                                                'sender',
                                                selectedOption
                                            )} />
                                    </div>
                                </div>
                            </div>
                            <BootstrapTable
                                keyField="id"
                                data={customSenders}
                                columns={columns}
                                wrapperClasses='table-responsive'
                                bordered={false}
                                pagination={paginationFactory(options)}
                                defaultSorted={defaultSorted}
                                noDataIndication={noData} />
                        </div>
                    </div>
                </div>
                <ModalDialog
                    show={showDeleteDialog}
                    onHide={() => setShowDeleteDialog(false)}
                    onAccept={async () => handleDeleteSender(senderToDelete.id)}
                    title={`Delete sender`}
                    acceptBtnText="Delete">
                    <span>{`Are you sure you want to delete this Sender? This action will impact the user's sender list.`}</span>
                </ModalDialog>
                <Modal
                    show={showHistory}
                    onHide={() => setShowHistory(false)}
                    backdrop="static"
                    keyboard={false}
                    className="historyModal"
                    centered>
                    <Modal.Header closeButton>
                        <div className='pageTitle'>
                            <span>History</span>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <BootstrapTable
                            keyField='updated_at'
                            data={senderHistory}
                            columns={senderHistoryColumns}
                            wrapperClasses='table-responsive'
                            bordered={false}
                            defaultSorted={defaultSortedHistory}
                            noDataIndication={noData} />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
};