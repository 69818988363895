import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { FormData } from '../helpers/FormData';
import { _paymentAdd, _paymentEdit, _paymentGetById, _usersGet } from '../services/apiConnections.service';

export const dateToLocalISO = (date) => {
    let timeZoneOffset = (new Date(date)).getTimezoneOffset() * 60000;
    let localISOTime = (new Date(date - timeZoneOffset)).toISOString();
    return localISOTime;
};

export const dateToUTC = (date) => {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()).toISOString();
};

export const UTCToLocalDate = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};

export const PaymentUpsert = (props) => {

    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        payment_date: '',
        paid_amount: '',
        description: ''
    });
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [formErrors, setFormErrors] = useState({});
    const [emails, setEmails] = useState([]);

    let navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        if (props.edit) {
            handlePaymentGet();
        }
    }, [props]);

    useEffect(() => {
        handleFormValueChange('payment_date', new Date(paymentDate));
    }, [paymentDate])

    const handleEmails = async () => {
        let result = await _usersGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.ID
                }
            });
            setEmails(arr);
        }
    };

    const handlePaymentGet = async () => {
        let result = await _paymentGetById(params.id);
        console.log("result: ", result);
        if (result.error) {
            console.log("--error: ", result.error);
        } else {
            setFormValues({
                email: {
                    label: result.user_email,
                    value: result.user_id
                },
                payment_date: result.payment_date,
                paid_amount: result.amount,
                description: result.details
            });
            setPaymentDate(UTCToLocalDate(new Date(result.payment_date)));
        }
    };

    const handleUpsert = async (event) => {
        event.preventDefault();
        let result;

        if (!props.edit)
            result = await _paymentAdd(formValues);
        else
            result = await _paymentEdit(formValues, params.id);

        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/payment');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'paid_amount':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        paid_amount: "Cannot be empty"
                    }));
                } else if (!/^\d+\.\d{1,2}$/.test(target.value)) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        paid_amount: "Paid Amount has to be in format *.**"
                    }));
                } else if (Number(target.value) < 1) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        paid_amount: "Amount must be a number between 1 and 10000"
                    }));
                } else if (Number(target.value) > 10000) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        paid_amount: "Amount must be a number between 1 and 10000"
                    }));
                }
                else {
                    let copy = { ...formErrors };
                    delete copy['paid_amount'];
                    setFormErrors(copy);
                }
                break;
            case 'description':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Cannot be empty"
                    }));
                } else if (target.value.length > 255) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Maximum number of characters - 255"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['description'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.email && formValues.paid_amount.length && formValues.description.length
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>{!props.edit ? 'Add Payment' : 'Edit Payment'}</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='email'>Email</label>
                        <div className='inputWrapper'>
                            <Select
                                id='email'
                                name='email'
                                className='customSelect'
                                classNamePrefix='select'
                                options={emails}
                                placeholder='Select Email'
                                value={formValues.email}
                                onChange={(selectedOption) => handleFormValueChange(
                                    'email',
                                    selectedOption
                                )} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className='inputLabel requiredInput'>Payment Date</label>
                        <div className='inputWrapper'>
                            <DatePicker
                                selected={paymentDate}
                                onChange={(date) => {
                                    setPaymentDate(new Date(date));
                                }}
                                selectsStart
                                dateFormat="MMMM d, yyyy"
                                maxDate={new Date()}
                                className='formInput datePicker'
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='paid_amount'>Paid Amount</label>
                        <div className='inputWrapper'>
                            <input
                                id='paid_amount'
                                name='paid_amount'
                                type="text"
                                className={`formInput ${formErrors.paid_amount ? 'invalidInput' : ''}`}
                                placeholder="Paid Amount"
                                value={formValues.paid_amount}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'paid_amount',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.paid_amount}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='description'>Description</label>
                        <div className='inputWrapper'>
                            <input
                                id='description'
                                name='description'
                                type="text"
                                className={`formInput ${formErrors.description ? 'invalidInput' : ''}`}
                                placeholder="Description"
                                value={formValues.description}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.description}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            onClick={handleUpsert}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                </div>
            </div>
        </div >
    );
};