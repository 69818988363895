import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { faAddressBook, faFileLines, faSmileBeam } from '@fortawesome/free-regular-svg-icons';
import { faUsers, faMoneyBill1, faCommentDollar, faMoneyCheckDollar, faTableList, faUserTag, faUserPen } from '@fortawesome/free-solid-svg-icons';

// import dasboardIcon from "../../images/icons/dashboard.png";

export const MenuLink = (props) => {

    const defindIcon = (icon) => {
        switch (icon) {
            case "prices":
                return faMoneyBill1;
            case "users":
                return faUsers;
            case "tariffs":
                return faCommentDollar;
            case "payment":
                return faMoneyCheckDollar;
            case "tariffPlan":
                return faTableList;
            case "customPrice":
                return faUserTag;
            case "customSender":
                return faUserPen;

            default:
                return faSmileBeam
        };
    };

    const defindPicIcon = (icon) => {
        switch (icon) {
            case "dashboard":
                return "/icons/dashboard.png";

            default:
                return;
        };
    };

    return (
        <Link className='nav-link' to={props.to}>
            {props.picIcon ?
                <div className='nav-icon' style={{ backgroundImage: `url(${defindPicIcon(props.icon)})` }}></div>
                :
                <FontAwesomeIcon className='nav-icon' icon={defindIcon(props.icon)} />
            }
            <span className='nav-name'>{props.name}</span>
        </Link>
    );
}