import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { FormData } from '../helpers/FormData';
import { _tariffPackagesListGet, _tariffPlanAssign, _usersListGet } from '../services/apiConnections.service';

export const TariffPlanAssign = () => {

    const [error, setError] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [formValues, handleFormValueChange, setFormValues] = FormData({
        email: '',
        tariff_package: '',
        description: ''
    });
    const [emails, setEmails] = useState([]);
    const [tariffPackages, setTariffPackages] = useState([]);
    const [formErrors, setFormErrors] = useState({});

    let navigate = useNavigate();

    useEffect(() => {
        if (!emails.length) {
            handleEmails();
        }
    }, [emails]);

    useEffect(() => {
        if (!tariffPackages.length) {
            handleTariffPackages();
        }
    }, [tariffPackages]);

    const handleEmails = async () => {
        let result = await _usersListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.users.map((e) => {
                return {
                    label: e.email,
                    value: e.id
                }
            });
            setEmails(arr);
        }
    };

    const handleTariffPackages = async () => {
        let result = await _tariffPackagesListGet();
        if (result.error) {
            console.log("error");
            setError(result.error);
        } else {
            let arr = result.tariff_packages.map((e) => {
                return {
                    label: e.name,
                    value: e.id
                }
            });
            setTariffPackages(arr);
        }
    };

    const handleAssign = async (event) => {
        event.preventDefault();
        let result = await _tariffPlanAssign(formValues);
        if (result.error) {
            setError(result.error);
        } else {
            console.log("success");
            navigate('/tariff-plan');
        }
    };

    const handleValidation = (target) => {
        switch (target.name) {
            case 'description':
                if (!target.value.length) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Cannot be empty"
                    }));
                } else if (target.value.length > 255) {
                    setFormErrors(formErrors => ({
                        ...formErrors,
                        description: "Maximum number of characters - 255"
                    }));
                } else {
                    let copy = { ...formErrors };
                    delete copy['description'];
                    setFormErrors(copy);
                }
                break;

            default: break;
        }
    };

    const formIsValid = () => {
        return !Object.keys(formErrors).length && formValues.email && formValues.tariff_package && formValues.description.length;
    };

    return (
        <div className='content'>
            <div>
                <div className='pageTitle'>
                    <span>Assign Tariff Plan</span>
                </div>
                {error && <p className='msgBlock errorMsg'>{error}</p>}
                <div className='pageContent'>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='email'>Email</label>
                        <div className='inputWrapper'>
                            <Select
                                id='email'
                                name='email'
                                className='customSelect'
                                classNamePrefix='select'
                                options={emails}
                                placeholder='Select Email'
                                value={formValues.email}
                                onChange={(selectedOption) => handleFormValueChange(
                                    'email',
                                    selectedOption
                                )} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='tariff_package'>Tariff Plan</label>
                        <div className='inputWrapper'>
                            <Select
                                id='tariff_package'
                                name='tariff_package'
                                className='customSelect'
                                classNamePrefix='select'
                                options={tariffPackages}
                                placeholder='Select Tariff Plan'
                                value={formValues.tariff_package}
                                onChange={(selectedOption) => handleFormValueChange(
                                    'tariff_package',
                                    selectedOption
                                )} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label
                            className='inputLabel requiredInput'
                            htmlFor='description'>Description</label>
                        <div className='inputWrapper'>
                            <input
                                id='description'
                                name='description'
                                type="text"
                                className={`formInput ${formErrors.description ? 'invalidInput' : ''}`}
                                placeholder="Description"
                                value={formValues.description}
                                required
                                onChange={(event) => {
                                    handleFormValueChange(
                                        'description',
                                        event.target.value
                                    )
                                }}
                                onKeyUp={(event) => { handleValidation(event.target) }}
                            />
                            <span className='fieldError'>{formErrors.description}</span>
                        </div>
                    </div>
                    <div className='buttonBlock'>
                        <button
                            onClick={handleAssign}
                            className={`mainBtn btn ${formIsValid() ? '' : 'disabledBtn'}`}
                            disabled={!formIsValid()}>Add</button>
                    </div>
                </div>
            </div>
        </div >
    );
};